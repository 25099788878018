// Others
import { db } from '@/config/firebase';

async function getNoOfActiveLocationsPerCompany(companyId) {
    const query = db.collection('storageLocations')
        .where("companyId", "==", companyId)
        .where("isActive", "==", "true");
    const snapshot = await query.get();
    return snapshot.size;
}

export default {
    getNoOfActiveLocationsPerCompany
}